/* You can add global styles to this file, and also import other style files */

@import "@ng-select/ng-select/themes/default.theme.css";
.red {
  color: red;
}
body {
  background-color: #f4f5f7;
}
.btn-check-next {
  padding-left: 30px;
  padding-right: 30px;
}
.outline-0 {
  outline: 0 !important;
}
.no-button {
  display: none !important;
}
::-ms-clear {
  display: none;
}
.progress-icon {
  position: relative;
}

.progress-icon i {
  position: absolute;
  right: 15px;
  z-index: 3;
  top: 10px;
  color: gray;
}
.cursor-pointer {
  cursor: pointer;
}
.hide-radio-inputs {
  display: none;
}
.f-18 {
  font-size: 18px;
}
.block-conent-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-data-available-info {
  text-align: center;
  font: normal normal medium 16px/19px Roboto;
  color: #3d708d;
  opacity: 1;
  background: #dcedf6 0% 0% no-repeat padding-box;
  border: 1px solid #c1def0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
}

.search-results-holder {
  ngb-typeahead-window {
    height: 350px !important;
    overflow: auto;
  }
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}